import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PrimeNGConfig} from "primeng/api";
import {NavigationEnd, Router} from "@angular/router";
import {AppComponentService} from "./app.component.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {isPlatformBrowser} from "@angular/common";
import {filter} from "rxjs";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'tucuate-web';
  private themes = {
    saga: 'assets/themes/saga/purple/theme.css',
    cuate: 'assets/themes/tucuate/purple/theme.css'
  };
  private currentTheme: HTMLLinkElement;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private router: Router,
              private titleService: Title,
              private primeNGConfig: PrimeNGConfig,
              private translateService: TranslateService,
              private appComponentService: AppComponentService,
              private googleTagManagerService: GoogleTagManagerService) {
    this.initTranslateService();
    this.setTheme('saga');

    this.appComponentService.theme
      .subscribe(value => {
        this.setTheme(value);
      });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.googleTagManagerService.addGtmToDom().then().catch();
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((value: NavigationEnd) => {
        setTimeout(() => {
          const gtmTag = {
            event: 'page_view',
            page_location: window.location.href,
            page_title: this.titleService.getTitle(),
            page_path: value.urlAfterRedirects
          };
          console.log(gtmTag);
          this.googleTagManagerService.pushTag(gtmTag).then();
        }, 0);
      });

    // this.router.events.forEach(item => {
    // if (item ins) {
    // const gtmTag = {
    //   event: 'page',
    //   pageName: item.url
    // };
    // console.log(item);
    // this.angularFireAnalytics.logEvent({
    //
    // });
    // }
    // });
  }

  public setTheme(theme: 'cuate' | 'saga'): void {
    const themeUrl = this.themes[theme];

    if (isPlatformBrowser(this.platformId)) {
      let themeElement = document.getElementById('theme-link');
      themeElement.setAttribute('href', themeUrl);
    }
  }

  private initTranslateService() {
    const browserLang = this.translateService.getBrowserLang();

    this.translateService.addLangs(['es']);
    this.translateService.setDefaultLang('es');
    this.translateService.use(browserLang?.match(/es/) ? browserLang : 'es');

    this.primeNGConfig.ripple = true;
    this.translateService.stream("primeng").subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }
}

import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {ApolloClient, ApolloLink, InMemoryCache, split} from '@apollo/client/core';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {environment} from '../environments/environment';
import {NgModule, PLATFORM_ID} from '@angular/core';
import {setContext} from '@apollo/client/link/context';
import {CurrentSession} from "@models/current-session";
import {isPlatformBrowser} from "@angular/common";

const uri = `${environment.coreHttpUrl}/graphql`;
const wsUri = `${environment.coreWsUrl}/graphql`;

function getToken(): string | null {
  const sessionString = sessionStorage.getItem('sims.session');

  if (sessionString == null) return null;
  const session = JSON.parse(sessionString) as CurrentSession;

  if (!session || !session.token) return null;

  return session.token;

  // return sessionString.substring(1, sessionString.length - 1);
}

export function createApollo(
  httpLink: HttpLink,
  platformId: Object
): ApolloClient<any> {
  const http = httpLink.create({
    uri: uri,
    // extractFiles: extractFiles
  });

  const auth = setContext((_, {headers}) => {
    const token = getToken();
    if (token === null) {
      return {
        headers: {
          ...headers
        },
      };
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  let apolloLink: ApolloLink;
  if (isPlatformBrowser(platformId)) {
    const ws = new WebSocketLink({
      uri: wsUri,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          const token = getToken();
          if (token === null) {
            return {};
          } else {
            return {
              Authorization: `Bearer ${getToken()}`
            }
          }
        },
      }
    });
    apolloLink = split(
      ({query}) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        );
      },
      ws,
      auth.concat(http),
    );
  } else {
    apolloLink = split(
      ({query}) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        );
      },
      auth.concat(http),
    );
  }

  return new ApolloClient<any>({
    link: ApolloLink.from([apolloLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  });
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, PLATFORM_ID]
    }
  ]
})
export class GraphqlModule {
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable()
export class AppComponentService {
  private mTheme: Subject<'saga' | 'cuate'> = new BehaviorSubject<'saga' | 'cuate'>('cuate');

  constructor() {
  }

  get theme() {
    return this.mTheme.asObservable();
  }

  public setTheme(theme: 'saga'|'cuate') {
    this.mTheme.next(theme);
  }
}

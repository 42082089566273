// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  coreHttpUrl: 'https://api.develop.tucuate.com/core',
  coreWsUrl: 'wss://api.develop.tucuate.com/core',
  firebase: {
    apiKey: "AIzaSyDsPGGEDA8VnJIfIjRXd0d1rv16F5JFnX8",
    authDomain: "tucuate-production.firebaseapp.com",
    projectId: "tucuate-production",
    storageBucket: "tucuate-production.appspot.com",
    messagingSenderId: "838996303367",
    appId: "1:838996303367:web:42368d06ddfa03444f94a2",
    measurementId: "G-SK4KLECMZB"
  },
  tagManager: {
    id: "GTM-P387F4M9"
  },
  recaptcha: {
    siteKeyV2: '6Lc5ZiwqAAAAABAwkl26JlqV1TkJN-HhmLpUDhzD',
    siteKeyV3: '6LeTFCwqAAAAAC1D-Rp1WTDDhOEwAU03qcucBaWM'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app.routing.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {GraphqlModule} from "./graphql.module";
import {NgxWebstorageModule} from "ngx-webstorage";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {
  faBars,
  faCalendarAlt,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faDollar,
  faEnvelope,
  faEye,
  faEyeSlash,
  faHome,
  faList,
  faListCheck,
  faLock,
  faMobileAlt,
  faMoneyBillTransfer,
  faPercent,
  faPhone,
  faPlus,
  faRightFromBracket,
  faSimCard,
  faSpinner,
  faUser,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import {AngularFireModule} from "@angular/fire/compat";
import {AppComponentService} from "./app.component.service";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {environment} from "@environment";

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    GraphqlModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
    }),
    NgxWebstorageModule.forRoot({prefix: 'sims', separator: '.', caseSensitive: true}),
    AngularFireModule.initializeApp(environment.firebase),
    GoogleTagManagerModule.forRoot({
      id: environment.tagManager.id,
    }),
  ],
  providers: [
    AppComponentService,
    provideClientHydration()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faUser, faLock, faEye, faEyeSlash, faHome, faList,
      faListCheck, faSimCard, faMoneyBillTransfer, faBars, faRightFromBracket,
      faSpinner, faCircleCheck, faCircleExclamation, faXmark,
      faCircle, faPhone, faDollar, faPercent, faChevronRight, faMobileAlt,
      faCalendarAlt, faPlus, faEnvelope
    );
  }
}
